// store/page/pageSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetStaticPageAliases as apiGetStaticPageAliases } from '../../services/StaticPagesService';

// Define an async thunk for fetching the page data
export const fetchAllalias = createAsyncThunk(
  'page/fetchAllalias',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiGetStaticPageAliases();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllalias.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllalias.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchAllalias.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default pageSlice.reducer;

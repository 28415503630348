// store/menu/menuSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMenuAllData as fetchMenuDataService } from '../../services/MenuService';

// Define an async thunk for fetching the menu data
export const fetchMenuData = createAsyncThunk(
  'menu/fetchMenuData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchMenuDataService();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMenuData.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchMenuData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default menuSlice.reducer;

import ApiService from './ApiService'

export async function apiFetchStaticPages(data) {
  return ApiService.fetchData({
    url: '/static-page',
    method: 'get',
    data,
  })
}

export async function apiCreateStaticPages(data) {
  return ApiService.fetchData({
    url: '/static-page',
    method: 'post',
    data,
  })
}

export async function apiGetStaticPageById(id, data) {
  return ApiService.fetchData({
    url: '/static-page/' + id,
    method: 'get',
    data,
  })
}

export async function apiUpdateStaticPage(id, data) {
  return ApiService.fetchData({
    url: '/static-page/' + id,
    method: 'put',
    data,
  })
}

export async function apiDeleteStaticPage(id, data) {
  return ApiService.fetchData({
    url: '/static-page/' + id,
    method: 'delete',
    data,
  })
}

export async function apiGetStaticPageAliases(data) {
  return ApiService.fetchData({
    url: '/static-page/alias/all',
    method: 'get',
    data,
  })
}

export async function apiGetStaticPageDataByAliase(alias, data) {
  return ApiService.fetchData({
    url: '/static-page/alias' + alias,
    method: 'get',
    data,
  })
}

import ApiService from './ApiService'

export async function fetchMenuAllData(data) {
  return ApiService.fetchData({
    url: '/menu',
    method: 'get',
    data,
  })
}

export async function apiCreateMenu(data) {
  return ApiService.fetchData({
    url: '/menu',
    method: 'post',
    data,
  })
}

export async function apiGetSingleMenuById(id, data) {
  return ApiService.fetchData({
    url: '/menu/' + id,
    method: 'get',
    data,
  })
}

export async function apiUpdateMenu(id, data) {
  return ApiService.fetchData({
    url: '/menu/' + id,
    method: 'put',
    data,
  })
}

export async function apiDeleteMenu(id, data) {
  return ApiService.fetchData({
    url: '/menu/' + id,
    method: 'delete',
    data,
  })
}

export async function apiGetAppParentMenu(data) {
  return ApiService.fetchData({
    url: '/menu/parents',
    method: 'get',
    data,
  })
}
